<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-xxl-9">
      <div class="card mt-xxl-n5">
        <div class="card-header">
          <ul class="nav 
                  nav-tabs-custom
                  rounded card-header-tabs 
                  border-bottom-0"
                  role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                <i class="fas fa-home"></i>
                Programmez une évalution
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body p-4">
          <div class="tab-content">
            <div class="tab-pane active" id="personalDetails" role="tabpanel">
              <form @submit.prevent="initCreation">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="filiere" class="form-label">Filière</label>
                      <Multiselect class="form-control" v-model="data.filiere"
                        :class="{ 'is-invalid': v$.data.filiere.$error && data.filiere || v$.data.filiere.$error && submited, }"
                        :close-on-select="true" :searchable="true" :show-labels="false" :options="facultiesOptions" />
                      <div v-for="(item, index) in v$.data.filiere.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.filiere || v$.data.filiere.$error && submited">{{ item.$message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="matiere" class="form-label">Matière</label>
                      <Multiselect class="form-control" v-model="data.matiere"
                        :class="{ 'is-invalid': v$.data.matiere.$error && data.matiere || v$.data.matiere.$error && submited, }"
                        :close-on-select="true" :searchable="true" :show-labels="false" :options="mattersOptions" />
                      <div v-for="(item, index) in v$.data.matiere.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.matiere || v$.data.matiere.$error && submited">{{ item.$message }}
                      </span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="typeExam" class="form-label">Type d'évalution</label>
                      <Multiselect class="form-control" v-model="data.typeExam"
                        :class="{ 'is-invalid': v$.data.typeExam.$error && data.typeExam || v$.data.typeExam.$error && submited, }"
                        :close-on-select="true" :searchable="true" :show-labels="false" :options="[
                          { value: 'Devoir', label: 'Devoir' },
                          { value: 'Partiel', label: 'Partiel' },
                        ]" />
                      <div v-for="(item, index) in v$.data.typeExam.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.typeExam || v$.data.typeExam.$error && submited">{{
                          item.$message }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="semestre" class="form-label">Semestre</label>
                      <Multiselect class="form-control" v-model="data.semestre"
                        :class="{ 'is-invalid': v$.data.semestre.$error && data.semestre || v$.data.semestre.$error && submited, }"
                        :close-on-select="true" :searchable="false" :show-labels="false" :options="[
                          { value: '1', label: '1' },
                          { value: '2', label: '2' },
                        ]" />
                      <div v-for="(item, index) in v$.data.semestre.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.semestre || v$.data.semestre.$error && submited">{{
                          item.$message }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="coef" class="form-label">Coeffiscient</label>
                      <input type="number" class="form-control" id="coef" v-model="data.coef" :class="{
                        'is-invalid': v$.data.coef.$error && data.coef || v$.data.coef.$error && submited,
                      }" placeholder=" " />
                      <div v-for="(item, index) in v$.data.coef.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.coef || v$.data.coef.$error && submited">{{ item.$message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="dateCompo" class="form-label">Date de composition
                      </label>

                      <flat-pickr class="form-control" v-model="data.dateCompo"
                        :class="{ 'is-invalid': v$.data.dateCompo.$error && data.dateCompo || v$.data.dateCompo.$error && submited, }" />

                      <div v-for="(item, index) in v$.data.dateCompo.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.dateCompo || v$.data.dateCompo.$error && submited">{{
                          item.$message }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-6">
                    <div class="mb-3">

                      <label for="heurCompo" class="form-label">
                        Heure de composition
                      </label>

                      <flat-pickr v-model="data.heurCompo" :config="timePicker" class="form-control flatpickr-input">
                      </flat-pickr>

                      <div v-for="(item, index) in v$.data.heurCompo.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.heurCompo || v$.data.heurCompo.$error && submited">{{
                          item.$message }}</span>
                      </div>

                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-12">
                    <div class="hstack gap-2 justify-content-end">
                      <button type="submit" class="btn btn-primary" :disabled="spanView">
                        <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>

import useVuelidate from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";
import Swal from "sweetalert2";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      data: {
        AnneeScolaire: localStorage.getItem('selectedYear'),
      },
      facultiesOptions: [],
      faculties: {},
      mattersOptions: [],
      matters: {},
      spanView: false,

      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },

      submited: false,

      title: "Nouvel Evaluation",
      items: [
        {
          text: "Evaluation",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      matiere: {
        required: helpers.withMessage("Veuillez choisir la matière", required),
      },
      semestre: {
        required: helpers.withMessage("Veuillez choisir le semestre", required),
      },
      typeExam: {
        required: helpers.withMessage("Veuillez choirsir le type d'évalution ", required),
      },
      coef: {
        required: helpers.withMessage("Veuillez entrer le coeffiscient de l'examen", required),
        maxLength: helpers.withMessage("le coeffiscient est un chiffre compris entre 0 et 9", maxLength(20)),
      },
      filiere: {
        required: helpers.withMessage("Veuillez selectionner une filière", required),
      },
      dateCompo: {
        required: helpers.withMessage("Veuillez entrer une date", required),
      },
      heurCompo: {
        required: helpers.withMessage("Veuillez entrer une heure", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    flatPickr
  },
  mounted() {
    this.v$.data.$touch();
    this.getAllFaculties();
    this.getAllMatters();
  },

  methods: {
    initCreation() {
      this.submited = true;

      this.createNewExam();
    },

    getAllFaculties() {
      const self = this;
      this.$store.dispatch('getRequest', { route: '/api/getAllFaculties', data: this.data })
        .then(
          function (response) {
            self.faculties = response.data.filieres;
            self.facultiesOptions = [],
              self.faculties.forEach(element => {

                self.facultiesOptions.push({ value: element.id, label: element.code });

              });
          },
          function (error) {
            if (error !== null) {
              console.log('error');
            }
          }
        )
    },

    getAllMatters() {
      const self = this;
      this.$store.dispatch('getRequest', { route: '/api/getAllMatters', data: this.data })
        .then(
          function (response) {
            self.matters = response.data.matieres;

            self.mattersOptions = [],

              self.matters.forEach(element => {

                self.mattersOptions.push({ value: element.id, label: element.title });

              });

          },
          function (error) {
            if (error !== null) {
              console.log('error');
            }
          }
        )
    },


    createNewExam() {
      this.spanView = true;
      const self = this;
      this.$store.dispatch('postRequest', { route: '/api/createNewExam', data: this.data })
        .then(
          function (response) {
            self.spanView = false;
            self.data = {};
            Swal.fire(response.data.message, "Examen programmé!", "success");
          },
          function (error) {
            self.spanView = false;
            if (error !== null) {
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        )
    },
  },
};
</script>
  
  